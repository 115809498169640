export default [
  {
    path: '/app/catalogs',
    name: 'Catalogs',
    component: () => import('@/views/Client/Catalogs/Index.vue'),
    meta: {
      pageTitle: 'Kataloglarım',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'CLIENT_CATALOGS',
      action: 'read',
    },
  },
  {
    path: '/app/catalogs/view/:id',
    name: 'CatalogView',
    component: () => import('@/views/Client/Catalogs/View.vue'),
    meta: {
      pageTitle: 'Kataloglarım',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/catalogs',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'CLIENT_CATALOGS',
      action: 'read',
    },
  },
]
