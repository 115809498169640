import axiosIns from '@/libs/axios'

const ROOT_URL = '/account'
export default {
  namespaced: true,
  state: {
    dataItem: {
      id: null,
      name: null,
      surname: null,
      fullname: null,
      email: null,
      phone: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        name: null,
        surname: null,
        fullname: null,
        email: null,
        phone: null,
      }
    },
  },
  actions: {
    getData({ commit }) {
      axiosIns
        .post(ROOT_URL)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, data) {
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
