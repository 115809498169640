export default [
  {
    path: '/app/offers',
    name: 'Offers',
    component: () => import('@/views/Client/Offers/Index.vue'),
    meta: {
      pageTitle: 'Tekliflerim',
      breadcrumb: [
        {
          text: 'Teklif Listesi',
          active: true,
        },
      ],
      resource: 'CLIENT_OFFERS',
      action: 'read',
    },
  },
  {
    path: '/app/offers/request',
    name: 'OfferRequest',
    component: () => import('@/views/Client/Offers/Request.vue'),
    meta: {
      pageTitle: 'Tekliflerim',
      breadcrumb: [
        {
          text: 'Teklif Listesi',
          to: '/app/offers',
          active: false,
        },
        {
          text: 'Teklif İste',
          active: true,
        },
      ],
      resource: 'CLIENT_OFFERS',
      action: 'read',
    },
  },
  {
    path: '/app/offers/view/:id',
    name: 'OfferView',
    component: () => import('@/views/Client/Offers/View.vue'),
    meta: {
      pageTitle: 'Tekliflerim',
      breadcrumb: [
        {
          text: 'Teklif Listesi',
          to: '/app/offers',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'CLIENT_OFFERS',
      action: 'read',
    },
  },
]
