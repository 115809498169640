import axiosIns from '@/libs/axios'

const ROOT_URL = '/baskets'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataItem: {
      id: null,
      notes: null,
      items: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    sendResult: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    sendResult(state) {
      return state.sendResult
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data.data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_SEND_RESULT(state, data) {
      state.sendResult = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        notes: null,
        items: [],
      }
    },
  },
  actions: {
    getDataList({ commit }) {
      axiosIns
        .post(ROOT_URL, { list: true })
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getData({ commit }) {
      axiosIns
        .post(ROOT_URL)
        .then(response => {
          if (response.data.data) {
            commit('SET_DATA_ITEM', response.data)
          } else {
            commit('RESET_DATA_ITEM')
          }
        })
        .catch(error => console.error(error))
    },
    addBasket({ commit }, data) {
      return axiosIns
        .post(`${ROOT_URL}/addBasket`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    removeItem({ commit }, data) {
      return axiosIns
        .post(`${ROOT_URL}/removeItem`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    sendRequest({ commit }, data) {
      return axiosIns
        .post(`${ROOT_URL}/sendRequest`, data)
        .then(response => {
          commit('SET_SEND_RESULT', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
