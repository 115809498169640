import axiosIns from '@/libs/axios'

const ROOT_URL = '/dashboard'
export default {
  namespaced: true,
  state: {
    counters: {
      catalogs: null,
      offers: null,
      messages: null,
    },
    loading: false,
  },
  getters: {
    counters(state) {
      return state.counters
    },
    loading(state) {
      return state.loading
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.counters = data.counters
    },
    SET_LOADING(state, data) {
      state.loading = data
    },
  },
  actions: {
    getData({ commit }) {
      commit('SET_LOADING', true)
      axiosIns
        .post(ROOT_URL)
        .then(response => {
          commit('SET_DATA', response.data.data)
          commit('SET_LOADING', false)
        })
        .catch(error => console.error(error))
    },
  },
}
