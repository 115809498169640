export default [
  {
    path: '/app/account',
    name: 'Account',
    component: () => import('@/views/Client/Account/Index.vue'),
    meta: {
      pageTitle: 'Hesabım',
      breadcrumb: [
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'CLIENT_ACCOUNT',
      action: 'read',
    },
  },
]
