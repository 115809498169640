import axiosIns from '@/libs/axios'

const ROOT_URL = '/chat_messages'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    searchData: {
      keyword: null,
    },
    dataItem: {
      id: null,
      room_code: null,
      username: null,
      catalog: null,
      avatar: null,
      id_catalogs: null,
      created: null,
      modified: null,
      closed: null,
      messages: [],
    },
    messageItem: {
      message: null,
      room_code: null,
    },
    lastId: null,
    roomCode: null,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataItem(state) {
      return state.dataItem
    },
    messageItem(state) {
      return state.messageItem
    },
    roomCode(state) {
      return state.roomCode
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {}
    },
    SET_DATA_LAST_ID(state, data) {
      state.lastId = data
    },
    SET_ROOM_CODE(state, data) {
      state.roomCode = data
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, roomCode) {
      return axiosIns
        .post(`${ROOT_URL}/messages`, { room_code: roomCode })
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    sendMessage({ commit }, params) {
      return axiosIns
        .post(`${ROOT_URL}/sendMessage`, params)
        .then(response => {
          commit('SET_DATA_LAST_ID', response.data.id)
          return response.data
        })
        .catch(error => console.error(error))
    },
    createMessage({ commit }, params) {
      return axiosIns
        .post(`${ROOT_URL}/createMessage`, params)
        .then(response => {
          commit('SET_ROOM_CODE', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    closedChat({ commit }, params) {
      return axiosIns
        .post(`${ROOT_URL}/closedChat`, params)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
