import axiosIns from '@/libs/axios'

const ROOT_URL = '/offers'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataItem: {
      lines: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data.data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
  },
  actions: {
    getDataList({ commit }) {
      axiosIns
        .post(ROOT_URL)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getData({ commit }, data) {
      return axiosIns
        .post(`${ROOT_URL}/view`, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    confirm({ commit }, data) {
      return axiosIns
        .post(`${ROOT_URL}/confirm`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
