import axiosIns from '@/libs/axios'

const ROOT_URL = '/catalogs'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataItem: {},
    productItem: {},
    openCatalog: false,
    id_catalog_tracings: null,
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataItem(state) {
      return state.dataItem
    },
    productItem(state) {
      return state.productItem
    },
    id_catalog_tracings(state) {
      return state.id_catalog_tracings
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data.data
      state.id_catalog_tracings = data.id_catalog_tracings
    },
    SET_PRODUCT_ITEM(state, data) {
      state.productItem = data
    },
    SET_OPEN_CATALOG(state, data) {
      state.openCatalog = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
  },
  actions: {
    getDataList({ commit }) {
      axiosIns
        .post(ROOT_URL)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getData({ commit }, data) {
      return axiosIns
        .post(`${ROOT_URL}/view`, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    getItem({ commit }, data) {
      axiosIns
        .post(`${ROOT_URL}/item`, data)
        .then(response => {
          commit('SET_PRODUCT_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    openCatalog({ commit }, data) {
      return axiosIns
        .post(`${ROOT_URL}/openCatalog`, data)
        .then(response => {
          commit('SET_OPEN_CATALOG', response.data.status)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
    interactions({ commit }, data) {
      return axiosIns
        .post(`${ROOT_URL}/interactions`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
  },
}
