export default [
  {
    path: '/app/messages',
    name: 'Messages',
    component: () => import('@/views/Client/Messages/Index.vue'),
    meta: {
      pageTitle: 'Mesajlarım',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'CLIENT_MESSAGES',
      action: 'read',
    },
  },
  {
    path: '/app/messages/view/:room_code',
    name: 'MessageView',
    component: () => import('@/views/Client/Messages/View.vue'),
    meta: {
      pageTitle: 'Mesajlarım',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/messages',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'CLIENT_MESSAGES',
      action: 'read',
    },
  },
]
