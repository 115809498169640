import Vue from 'vue'
import Vuex from 'vuex'

/* Admin:Core */
import verticalMenu from './vertical-menu'
import appConfig from './app-config'
import app from './app'
/* Admin:App */
import account from './Client/Account'
import catalogs from './Client/Catalogs'
import chatMessages from './Client/Chat_messages'
import company from './Client/Company'
import dashboard from './Client/Dashboard'
import baskets from './Client/Baskets'
import offers from './Client/Offers'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    account,
    catalogs,
    chatMessages,
    company,
    dashboard,
    baskets,
    offers,
  },
  strict: process.env.DEV,
})
